<template>
    <div>
        <h1>Redirecting to the calendly link...</h1>
    </div>
</template>

<script>
export default {
    created() {
        window.location.href = "https://calendly.com/krystelmoore/15min"
    }
}
</script>

<style scoped>

</style>